<template>
  <div class="swiper-slide">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
};
</script>

<style scoped lang="scss">
.swiper-slide {
  height: auto;
  display: flex;
}
</style>
